import { Container, Row, Col } from "react-bootstrap";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from './SpeedPlan.module.scss';
import {noWidows} from "../../globals";
import { useState } from "react";
import { useEffect } from "react";

const SpeedPlan = () => {

    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchPlan = async () => {        
        let url = `${process.env.REACT_APP_API_ENDPOINT}/v1/nbn-fibre-plans/?fibreType=NbnCo&nbnRegion=metro&nbnServiceClass=1&maxSpeed=1000;`;        
        setLoading(true);
        try {
            const response = await fetch(url)
            const data = await response.json()
            setPlans(data.plans.slice(-2));            
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);          
        }
    }

    useEffect(() => {
        fetchPlan();
    }, []);

    const Carousel = <ReactOwlCarousel
        mergeFit={false}
        autoWidth={false}
        items="1"
        stagePadding={0}
        center={true}
        margin={20}
        nav={false}
        startPosition={0}
        dots={true}
    >
        {plans.map((item, index) => {
            return (
                <div className={styles.mobile} key={index}>                    
                    <h4 className="bold font-larger color-indigo" dangerouslySetInnerHTML={{__html: item.planHeading}} />                                 
                    {index === 0 ? <p className={`m-0 font-small ${styles.desc}`}>For those who love speed </p> : <p className={`m-0 font-small ${styles.desc}`}>Our fastest internet plan for homes <br /> with HFC or Fibre-to-the-homess</p>}
                    <div className={`${styles.divider} py-4 my-3`}>
                        <p className="bold font-large color-indigo m-0">
                            {item.planFullSpeed} Mbps
                        </p>
                        <p className="m-0 font-small">Typical {item.eveningSpeed} evening speed</p>
                    </div>
                    <p className={`color-indigo bold m-0 ${styles.price}`}>
                        ${item.special.planPrice}/mth
                    </p>
                    <p className="m-0 font-small">For the first {item.special.duration} <br /> then ${item.price}/mth ongoing</p>
                    <p className="m-0 mt-2 font-small">No lock-in | Total min, $105</p>
                </div>
            )
        })}         
    </ReactOwlCarousel>

    return (
        <Container className="mt-5 pb-lg-5 pb-0 text-center">
            <div className={`${styles['plan-wrapper']} p-4`}>
                <h2 className="color-lime">
                    Exetel’s high-speed, unlimited data internet plans
                </h2>
                <p className="color-white mt-3 mb-5" dangerouslySetInnerHTML={{__html: noWidows('If you’re being short-changed by your provider, here’s some Exetel nbn™ options to satisfy your need for speed:')}} />
                <Row className={`${styles.row} d-none d-md-flex`}>
                    {!loading && plans.map((item, index) => {
                        return (
                            <Col md={6} sm={12} className={`${styles.column} p-4`} key={index}>                    
                                <h4 className="bold font-larger color-indigo" dangerouslySetInnerHTML={{__html: item.planHeading}} />                                 
                                {index === 0 ? <p className={`m-0 font-small ${styles.desc}`}>For those who love speed </p> : <p className={`m-0 font-small ${styles.desc}`}>Our fastest internet plan for homes <br /> with HFC or Fibre-to-the-homess</p>}
                                <div className={`${styles.divider} py-4 my-3`}>
                                    <p className="bold font-large color-indigo m-0">
                                        {item.planFullSpeed} Mbps
                                    </p>
                                    <p className="m-0 font-small">Typical {item.eveningSpeed} evening speed</p>
                                </div>
                                <p className={`color-indigo bold m-0 ${styles.price}`}>
                                    ${item.special.planPrice}/mth
                                </p>
                                <p className="m-0 font-small">For the first {item.special.duration} <br /> then ${item.price}/mth ongoing</p>
                                <p className="m-0 mt-2 font-small">No lock-in | Total min, $105</p>
                            </Col>
                        )
                    })}                    
                </Row>
                <div className="d-block d-md-none">
                    {plans.length > 0 && Carousel}
                    <p className="m-0 mt-3 font-large semi-bold color-white">Switch to an Exetel high-speed nbn™ plan today</p>
                    <a className={`${styles['plan-btn']} btn font-medium bold mt-3 w-100 color-indigo`} href="https://www.exetel.com.au/broadband/nbn" target="_blank" rel="noreferrer">View Plan</a>
                </div>
            </div>
            <a className="btn font-medium bold mt-4 color-indigo d-none d-md-inline-block" href="https://www.exetel.com.au/broadband/nbn" target="_blank" rel="noreferrer">Switch to an Exetel high-speed nbn™ plan today</a>
        </Container>
    )
}
export default SpeedPlan