import { Row, Container } from "react-bootstrap"
import Icon from '../../images/icon-exefix.svg';
import styles from './ExefixBanner.module.scss';

const ExefixBanner = () => {
    return (
        <Container className="mt-5">
            <div className={styles.panel}>
                <Row className={`p-3 d-flex align-items-center ${styles.row}`}>                    
                    <img src={Icon} alt="ExeFix Icon" />
                    <div className="col-12 col-lg-10">
                        <h3 className="bold m-0 mb-3">Having network speed troubles?</h3>
                        <p className="bold m-0 font-smaller">Use the ExeFix app to: <br className="d-inline d-lg-none" /> <span className="color-lime regular">Increase internet speeds</span> + <span className="color-lime regular">Improve WiFi coverage</span> + <span className="color-lime regular">Fix video buffering</span></p>
                    </div>
                </Row>
                <a href="https://exetel.com.au/exefix" className="d-lg-none d-block m-auto color-white mb-3" target="_blank" rel="noreferrer">Get the App now</a>
                <div className={styles.link}>
                    <a href="https://exetel.com.au/exefix" target="_blank" rel="noreferrer">Get the App now</a>
                </div>
            </div>
        </Container>
  )
}
export default ExefixBanner