import PropTypes from "prop-types";
import { Generic, JSONLD } from "react-structured-data";

const FaqStructuredData = (props) => {

    let data = [
        {
            "@type": "Question",
            "name": "What is download speed?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Download speed refers to the speed your internet draws data to your devices. You need fast a download speed to:</p><ul><li>Stream high definition or 4K videos</li><li>Play high definition games</li><li>Load web pages quickly</li></ul>"
            }
        },
        {
            "@type": "Question",
            "name": "What is upload speed?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Upload speed refers to the speed your device loads data to the internet. You need fast upload speeds to:</p><ul><li>Save data to the cloud</li><li>Send photos and videos</li><li>Join video calls</li></ul>"
            }
        },
        {
            "@type": "Question",
            "name": "What is ping?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Ping is how a speed test measures the reaction time of your internet connection. It refers to how long it takes your device to get a response after it’s sent out a request to the internet. The lower your ping number, the faster your reaction time. <br /> You use ping for:</p><ul><li>Lag-free online gaming and video calls</li></ul>"
            }
        },
        {
            "@type": "Question",
            "name": "What is jitter?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Jitter is how a speed test measures your ping fluctuation. A high jitter result often means you have an unstable connection.</p>"
            }
        },
        {
            "@type": "Question",
            "name": "What does Mbps mean?",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Mbps stands for megabits per second. <br /> It’s how a speed test measures how much data your device is sending and receiving every second. The higher your Mbps result, the faster your internet connection.</p>"
            }
        },
        
    ];

    return (
        <JSONLD>
            <Generic type="FAQPage" jsonldtype="FAQPage" schema={{mainEntity: [data]}} />
        </JSONLD>
    );

};

FaqStructuredData.propTypes = {
    faqs: PropTypes.array.isRequired
};

export default FaqStructuredData;