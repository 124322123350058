import { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import styles from './SpeedTab.module.scss';
import Arrow from '../../images/arrow-right.svg';
import speedMeasure from '../../data/speedMeasure.json';
import { gsap } from "gsap/all";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import FaqStructuredData from '../FaqStructuredData';

gsap.registerPlugin(ScrollToPlugin);

const SpeedTab = () => {

    const [value, setValue] = useState(0);
    const tabContainer = useRef(null);
    
    const data = speedMeasure.data;

    const handleArrowClick = () => {
        gsap.to(tabContainer.current, 0.5, { scrollTo: {x: tabContainer.current.scrollLeft + 200} });
    }
    
    return (
        <Container className="mt-5 text-center">
            <div className={styles.tab}>
                <h2>How is a speed test measured?</h2>
                <p className="m-0 mb-4">A speed test is measured by five things: </p>
                <FaqStructuredData faqs={data} />
                <div className="d-flex position-relative">
                    <div className={`d-flex ${styles.tabs}`} ref={tabContainer}>
                        {data.map((item, index) => {
                            return (
                                <button
                                    className={`${styles['tab-button']} ${index === value && styles.active} btn bold`}
                                    key={index}
                                    onClick={() => setValue(index)}
                                >
                                    {item[0]}
                                </button>
                            )
                        })}
                    </div>
                    <div className={`d-md-none d-flex ${styles.arrow}`} onClick={handleArrowClick}>
                        <img src={Arrow} alt="arrow-right" />
                    </div>
                </div>
                <div className={styles['tab-content']}>
                    {data[value].map((item, key) => {
                        if (key !== 0) {
                            return <p key={key} className="m-0 mb-2" dangerouslySetInnerHTML={{__html: item}} />;
                        }
                    })}
                </div>
            </div>
        </Container>
    )
}
export default SpeedTab