import { Container, Row, Col } from "react-bootstrap";
import data from '../../data/navigation.json';
import styles from "./Footer.module.scss";

const Footer = () => {

    const today = new Date();
    return <footer id="footer" className={styles.footer}>
        <Container>
            <Row className={styles.row}>
                <Col md={6} lg={2}>
                    <h5>nbn&trade;</h5>
                    <ul>
                        {data.footer.nbn.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: item.title}} />
                                </li>
                            )
                        })}
                    </ul>
                </Col>
                <Col md={6} lg={2} >
                    <h5>Broadband</h5>
                    <ul>
                        {data.footer.broadband.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: item.title}} />
                                </li>
                            );
                        })}
                    </ul>
                </Col>
                <Col md={6} lg={2} >
                    <h5>Phone</h5>
                    <ul>
                        {data.footer.phone.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: item.title}} />
                                </li>
                            );
                        })}
                    </ul>
                </Col>
                <Col md={6} lg={2} >
                    <h5>About us</h5>
                    <ul>
                        {data.footer.about.map((item, index) => {
                            return (
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{__html: item.title}} />
                                </li>
                            );
                        })}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mt-5 mb-4 mb-lg-0 text-center">
                    <div className={styles.logo}>
                        <a href="https://www.exetel.com.au/" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.13 57.03">
                                <path fill="#009f4d" d="M45.53,35.09a2.59,2.59,0,0,0-1.27.3,3.83,3.83,0,0,0,2.81,6.32,3.72,3.72,0,0,0,1.69-.4A3.47,3.47,0,0,1,46,42.63a3.79,3.79,0,0,1-3.78-3.77,3.88,3.88,0,0,1,2.07-3.47,2.79,2.79,0,0,1,5.06,1.68,2.61,2.61,0,0,1-.15.87L48,38.76H43.47l1.17-.82h3.42v0a2.59,2.59,0,0,0-2.53-2.83m-4.81,6.79a2.25,2.25,0,0,0,.46,0,2,2,0,0,1-1.61.79,2.05,2.05,0,0,1-2-2V35.31h-1.2l2.61-1.84v1h2.68l-1.17.84H38.93v4.53c0,1.35.67,2,1.79,2m-5.5-3.94-1.17.82H29.52l1.17-.82h3.42v0a2.6,2.6,0,0,0-3.8-2.53,3.78,3.78,0,0,0,4.5,5.92A3.49,3.49,0,0,1,32,42.63a3.79,3.79,0,0,1-3.79-3.77,3.88,3.88,0,0,1,2.08-3.47,2.78,2.78,0,0,1,5.05,1.68,2.6,2.6,0,0,1-.14.87m-9,4.77L23.46,39l-2.39,3.36h-.92L23,38.46l-2.61-3.37,1.19-.87,2.28,3,2-2.79h.88l-2.42,3.37,3.15,4ZM19,37.94l-1.17.82H13.25l1.17-.82h3.42v0A2.6,2.6,0,0,0,14,35.39a3.83,3.83,0,0,0,2.8,6.32,3.7,3.7,0,0,0,1.7-.4,3.49,3.49,0,0,1-2.79,1.32A3.79,3.79,0,0,1,12,38.86,3.88,3.88,0,0,1,14,35.39a2.79,2.79,0,0,1,5.06,1.68,2.61,2.61,0,0,1-.15.87m32.29-6,1.41-1V41.61l4.41-1.92L39.85.24.4,17.46,17.62,56.9,51.24,42.23Z"/>
                                <path fill="#fff" d="M59.72,31.77h0l-.8,2h-.2l-.8-2h0v2h-.32V31.48h.53l.71,1.8.69-1.8H60v2.29h-.32Zm-3.57,0h-.74v-.29h1.8v.29h-.74v2h-.32Z"/>
                            </svg>
                        </a>                        
                    </div>
                </Col>
            </Row>
            <div className="text-center color-indigo mt-5 font-small">
                Copyright &copy; {today.getFullYear()} Exetel Pty Ltd <br className="d-lg-none" />ABN: 350 979 865 46 - All rights reserved.
            </div>
        </Container>
    </footer>;
};
export default Footer;
