import { Container, Table } from "react-bootstrap";
import testResult from '../../data/testResult.json';
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from "./SpeedTestResult.module.scss";
import {noWidows} from "../../globals";

const SpeedTestResult = () => {

    const Carousel = <ReactOwlCarousel
        mergeFit={false}
        autoWidth={false}
        items="1"
        stagePadding={40}
        center={true}
        margin={25}
        nav={false}
        startPosition={0}
        dots={true}
    >
        {testResult.data.map((item, index) => {
            return (
                <div className={styles['result-container']} key={index}>
                    <h4 className="bold font-large mb-3">{item.speed}</h4>
                    <p className="m-0 font-small">{item.content}</p>
                </div>
            )
        })}
    </ReactOwlCarousel>

    return (
        <Container className="mt-5 text-center">
            <h2 className="color-lime" dangerouslySetInnerHTML={{__html: noWidows('What speeds are right for me?')}} />
            <p className="mb-4">
                This is the equivalent of asking "How long is a piece of string?" - everyone’s needs, and households are different. So, instead of boring you with a list of things you need to consider, here’s a quick reference guide of speeds and what they can generally handle:
            </p>
            <div className={`${styles["table-container"]} d-none d-md-block`}>
                <Table responsive bordered className="color-indigo">
                    <thead>
                        <tr className="bold font-smaller">
                            {testResult.data.map((item, index) => {
                                return (
                                    <th key={index}>{item.speed}</th>
                                )
                            })}                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="font-small">
                            {testResult.data.map((item, index) => {
                                return (
                                    <td key={index}>{item.content}</td>
                                )
                            })}                             
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className="d-block d-md-none">
                {Carousel}
            </div>
            <p className="mt-4">If you need further information about our nbn™ plans, download our <a href="https://files.exetel.com.au/terms/NBN_Key_Facts_Sheet.pdf" target="_blank" rel="noopener noreffer" className="color-lime">fact sheet</a> today.</p>
        </Container>
    );
};
export default SpeedTestResult;
