import styles from './ExpandableList.module.scss';
import PropTypes from 'prop-types';
import { gsap } from "gsap";

const ExpandableList = (props) => {

    const items = [];
    const rects = [];
    const circles = [];

    const handleClick = (key) => {
        let item = items[key];
        let rect = rects[key];
        let circle = circles[key];

        if (items[key].classList.contains('closed')) {
            animateOpen(item, rect, circle);
        } else {
            animateClosed(item, rect, circle);
        }
    };

    const animateOpen = (item, rect, circle) => {
        item.classList.remove('closed');
        item.classList.add('open');

        let tl = gsap.timeline(),
            content = item.querySelector('.content');

        tl.set(content, {display: 'block', height: 'auto', paddingTop: '0px', paddingBottom: '10px'});
        tl.from(content,  {duration: 0.25, height: 0});
        tl.to(content, {duration: 0.2, opacity: 1}, 0);
        tl.to(rect, {duration: 0.2, rotation: 90, transformOrigin: '50% 50%'}, 0);
        tl.to(circle, {duration: 0.2, opacity: 1}, 0);
    };

    const animateClosed = (item, rect, circle) => {
        item.classList.remove('open');
        item.classList.add('closed');

        let tl = gsap.timeline(),
            title = item.querySelector('.title'),
            content = item.querySelector('.content');

        tl.to(content, {duration: 0.25, height: 0, paddingTop: 0, paddingBottom: 0});
        tl.to(title, { duration: 0.25, paddingBottom: '20px' }, 0);
        tl.set(content, {display: 'none', height: 'auto'});
        tl.to(rect, {duration: 0.2, rotation: 0, transformOrigin: '50% 50%'}, 0);
        tl.to(circle, {duration: 0.2, opacity: 1}, 0);
    };

    return (
        <div className={styles['expandable-list']}>
            {props.data.map((item, index) => {
                return (
                    <div key={index} className={`${styles.item} closed`} ref={el => items[index] = el}>
                        <div className='d-flex align-items-center'>
                            <div className={`${styles.title} title`} onClick={() => handleClick(index)} dangerouslySetInnerHTML={{__html: `<span class=${styles.number}>${index + 1}.</span> ${item.title}`}} />
                            <svg width="30px" height="30px" viewBox="0 0 30 30" className={`${styles.icon} icon`} onClick={() => handleClick(index)}>
                                <circle ref={el => circles[index] = el} fill="#C2EAC8" opacity="1" cx="15" cy="15" r="15" />
                                <rect ref={el => rects[index] = el} fill="#004346" x="13" y="7" width="4" height="16" />
                                <rect fill="#004346" transform="translate(15.000000, 15.000000) rotate(-90.000000) translate(-15.000000, -15.000000) " x="13" y="7" width="4" height="16" />
                            </svg>
                        </div>
                        <div className={`${styles.content} content`}>
                            {item.content}
                        </div>
                    </div>
                );
            })}
        </div>
    );

};

ExpandableList.propTypes = {
    data: PropTypes.array.isRequired,
};

export default ExpandableList;