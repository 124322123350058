import { Container } from "react-bootstrap";
import styles from './Title.module.scss';

const Title = () => {
    return (
        <Container className={styles.container}>
            <h1 className="color-white text-center">Exetel speed test</h1>
            <p className="color-white text-center mt-lg-5 mt-2 mb-3">
                How do you know you’re getting the internet speeds you were promised? Easy, run an Exetel speed test and find out in seconds!
            </p>
        </Container>
    );
};
export default Title;
