import React, { useState, useRef, useEffect } from "react";
import Logo from '../../images/exetel-logo.svg';
import MenuBar from '../../images/icon-menu-mobile.svg';
import MenuClose from '../../images/icon-close.svg';
import navigation from '../../data/navigation.json';
import styles from './Header.module.scss';

const Header = (props) => {

    const [showLinks, setShowLinks] = useState(false);
    const linksContainerRef = useRef(null);
    const linksRef = useRef(null);

    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    useEffect(() => {
        if (showLinks) {
            linksContainerRef.current.style.height = `100vh`;
        } else {
            linksContainerRef.current.style.height = "0px";
        }
    }, [showLinks]);

    return (
        <header>
            <nav className={styles.container}>
                <div className={styles['inner-container']}>
                    <div className={`${styles['nav-header']} d-lg-flex d-none`}>
                        <a href="https://exetel.com.au" target="_blank" rel="noreferrer">
                            <img className={styles.logo} src={Logo} alt="logo" />
                        </a>                      
                    </div>
                    <div className={`${styles['nav-header']} d-flex d-lg-none`}>
                        <div>
                            <a href="https://exetel.com.au" target="_blank" rel="noreferrer">
                                <img className={styles.logo} src={Logo} alt="logo" />
                            </a>
                        </div>
                        <a className={styles.number} href="tel:133938">
                            13 39 38
                        </a>
                        <button className={`${styles['nav-toggle']} btn`} onClick={toggleLinks}>
                            <img src={!showLinks ? MenuBar : MenuClose} alt="menu-toggle" />
                        </button>
                    </div>
                    <div className={styles['links-container']} ref={linksContainerRef}>
                        <div className="py-5 px-3 p-lg-0" ref={linksRef}>
                            <div className={styles['bg-mask']}></div>
                            <div className={styles['place-holder']}></div>
                            <ul className={styles.links}>
                                {navigation.items.map((link) => {
                                    const { id, url, title } = link;
                                    return (
                                        <li key={id}>
                                            <a href={url} target="_blank" rel="noreferrer">{title}</a>
                                        </li>
                                    );
                                })}
                            </ul>
                            <div className={styles['contact-mobile']}>
                                <a href="https://exetel.com.au/contact-us" target="_blank" rel="noreferrer">Contact Us</a>
                                <a href="https://my.exetel.com.au/login" target="_blank" rel="noreferrer">Sign into My Exetel</a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.contact}>
                        <ul className={styles.links}>
                            <li className={styles.border}><a className="color-lime bold" href="https://exetel.com.au/contact-us" target="_blank" rel="noreferrer">Contact Us</a></li>
                            <li className={styles.border}><a className="color-lime bold" href="https://my.exetel.com.au/login" target="_blank" rel="noreferrer">Sign into My Exetel</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );

};

Header.propTypes = {

};

export default Header;