import { Container } from "react-bootstrap";
import data from '../../data/faq.json';
import ExpandableList from "../ExpandableList/ExpandableList";
import styles from './SpeedTestFaq.module.scss';
import {noWidows} from "../../globals";

const SpeedTestFaq = () => {
    return (
        <Container className={`my-5 ${styles.container}`}>
            <h2 className="bold color-lime">
                Why is running a speed <br className="d-inline d-md-none" /> test important?
            </h2>
            <p className="mt-4">Aside from glitchy video and sound, or lagging gameplay, how else will you know if you’re getting the speeds you’re paying for?</p>
            <p>We suggest running tests at different times of the day to keep things interesting and see if there are specific dropouts at certain times... like in the evening.</p>
            <p>Fun Fact: The ACCC introduced the use of ‘evening speeds’ (when everyone is home and the internet is slower) to ensure providers were being transparent about their speeds, and to help customers make better decisions</p>
            <div className={`${styles.questions} mt-5`}>
                <h2 className="bold color-lime" dangerouslySetInnerHTML={{__html: noWidows('What can affect internet speed?')}} />
                <p className="m-0 my-4">If your internet speeds aren’t what you were expecting, here’s some things that may be causing issues you weren’t aware of: </p>
                <ol className={`${styles.list} d-none d-md-block`}>
                    {data.data.map((item, key) => {
                        return (
                            <li key={key} className="text-left">
                                <p><span className='bold'>{item.title}</span><br/><span dangerouslySetInnerHTML={{__html: noWidows(item.content)}} /></p>
                            </li>
                        );
                    })}
                </ol>
                <div className="d-block d-md-none">
                    <ExpandableList data={data.data} />
                </div>
            </div>
            <div className="mt-5">
                <h2 className="bold color-lime">
                    Everyone could do with a little fibre in their life
                </h2>
                <p className="m-0 mt-4">
                    If you’ve moved into a newly built apartment or estate, there’s a good chance you have access to a high-speed fibre network and be eligible for an Exetel Fast-Fibre plan. Find out more about our plans and if your place is eligible today!
                </p>
                <div className="d-md-block d-none text-center">
                    <a className="btn font-medium bold mt-5 color-indigo ml-auto" href="https://www.exetel.com.au/broadband/fibre" target="_blank" rel="noreferrer">
                        Explore our unbeatable Fast-Fibre plans now
                    </a>
                </div>
                <div className="d-block d-md-none">
                    <p className="semi-bold color-white font-large m-0 mt-5">
                        Explore our unbeatable<br />Fast-Fibre plans now
                    </p>
                    <a className="btn font-medium bold mt-3 color-indigo ml-auto w-100" href="https://www.exetel.com.au/broadband/fibre" target="_blank" rel="noreferrer">
                        View Plans
                    </a>
                </div>
            </div>
        </Container>
    )
}
export default SpeedTestFaq